import React, { Suspense, lazy, useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import Helmet from "react-helmet";

// import Home from '../views/home';
// import Invoice from '../views/invoice'
// import PaymentLink from '../views/paymentLink';
const Home = lazy(() => import("../views/home"));
const Invoice = lazy(() => import("../views/invoice"));
const PaymentLink = lazy(() => import("../views/paymentLink"));

// function getFaviconEl() {
//   return document.getElementById("favicon");
// }

const Routes = () => {
  // const favicon = getFaviconEl();
  // useEffect(() => {
  //   if (favicon) {
  //     favicon.href =
  //       process.env.REACT_APP_LOGO ||
  //       "https://assets.seerbitapi.com/images/icon.svg";
  //   }
  // }, [favicon]);

  return (
    <div>
      <Helmet
        title={process.env.REACT_APP_TITLE || "Seerbit Pay"}
        meta={[
          {
            name: "description",
            content:
              process.env.REACT_APP_DESCRIPTION || "Seerbit Payment Link",
          },
        ]}
        // link={[
        //   {
        //     rel: "icon",
        //     type: "image/png",
        //     href:
        //       process.env.REACT_APP_LOGO ||
        //       "https://assets.seerbitapi.com/images/icon.svg",
        //   },
        // ]}
      />
      <Suspense fallback={<div></div>}>
        <BrowserRouter>
          {/* <main> */}
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/invoice" component={Invoice} />
            <Route path="*" component={PaymentLink} />
          </Switch>
          {/* </main> */}
        </BrowserRouter>
      </Suspense>
    </div>
  );
};

export default Routes;
